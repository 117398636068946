import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, PaginationItem, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ReactComponent as PreviousIcon } from '../../Assets/Images/previous.svg';
import { ReactComponent as NextIcon } from '../../Assets/Images/next.svg';
import TextField from '@mui/material/TextField';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Airplain from '../../Assets/Images/airplane.png';
import RevenueIcon from '../../Assets/Images/flight.png';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const FlightSearch = () => {
    let { flightid } = useParams();
    const [flightData, setflightData] = useState<any>([])
    const [summaryTable, setSummaryTable] = useState<any>([])
    const [isConsignmentFound, setisConsignmentFound] = useState<Boolean>(false);
    const [consignmentPlans, setConsignmentPlans] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch flight info
                const flightInfoResponse = await axios.get(`https://test.uatsharpssop.co.uk/flight_info_id?id=${flightid}`);
                const data = flightInfoResponse?.data?.flights[0];
                setflightData(data);

                // Fetch flight summary based on flight and flight date
                setisConsignmentFound(false);
                if (data?.flight && data?.flightdate) {
                    const url = `https://test.uatsharpssop.co.uk/flight_summary?flight=${data.flight}&flight_date=${data.flightdate}`;
                    const summaryResponse = await axios.get(url);
                    setSummaryTable(summaryResponse?.data?.flight_summary);
                    setisConsignmentFound(true);
                    setConsignmentPlans(summaryResponse?.data?.flight_summary.map(() => ''))
                } else {
                    console.error('Missing flight or flight date in response data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error state or logging as needed
            }
        };

        fetchData();
    }, [flightid]);

    const handleDropdownChange = (index: any, value: string) => {
        const newConsignmentPlans: any = [...consignmentPlans];
        newConsignmentPlans[index] = value;
        setConsignmentPlans(newConsignmentPlans);
    };

    return (
        <Box>
            <Box sx={{
                paddingTop: '10px',
                paddingLeft: '10px',
            }}>
                <Typography sx={{
                    color: '#121111',
                    fontSize: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px'
                }}>  <img src={RevenueIcon} alt="Airplane" /> Revenue summary</Typography>
            </Box>
            {/* <Box sx={{
                display: 'flex',
                gap: '45px',
                marginTop: '15px',
                marginLeft: '15px',
                marginBottom: '25px',
                flexWrap: 'wrap',
                '@media only screen and (max-width: 991px)': {
                    gap: '15px',
                },

            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '20px',
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                        border: '1px solid #75757552;',
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <img src={Airplain} alt="Revenue Icon" />
                        <Box>
                            <Typography sx={{
                                color: '#4C5F78',
                                fontSize: '24px',
                                fontWeight: '600',
                                '@media only screen and (max-width: 991px)': {
                                    fontSize: '20px',
                                },
                            }}>{flightData?.origin}</Typography>
                            <Typography sx={{
                                color: '#4C5F78',
                                fontSize: '16px',
                                fontWeight: '300',
                            }}></Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Org</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.origin
                            }</Typography>
                    </Box>

                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',

                        }}>Dest</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.destination
                            }</Typography>
                    </Box>

                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Aircraft</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.flight
                            }</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Carrier</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.aircraft_type
                            }</Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '25px',
                    flexWrap: 'wrap',
                    '@media only screen and (max-width: 575px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        border: '1px solid #75757552',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Revenue</Typography>
                        <Typography sx={{
                            color: '#37AE68',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0).toFixed(2)}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Interline</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(summaryTable.reduce((acc: Number, curr: any) => acc + curr.interlinecost, 0).toFixed(2)) || '-'}</Typography>
                    </Box>



                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Truck/Supplier</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(summaryTable.reduce((acc: Number, curr: any) => acc + curr.truckcost, 0).toFixed(2)) || '-'}</Typography>
                    </Box>


                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Cost</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(
                            Number(summaryTable.reduce((acc: Number, curr: any) => acc + curr.truckcost, 0).toFixed(2))
                            +
                            Number(summaryTable.reduce((acc: Number, curr: any) => acc + curr.interlinecost, 0).toFixed(2))
                        ).toFixed(2) || '-'}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Margin before AL</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>
                            {(() => {
                                const revenueTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0);
                                const truckCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.truckcost, 0);
                                const interlineCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.interlinecost, 0);
                                const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                                return marginBeforeAL.toFixed(2);
                            })()}
                        </Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total AL Expenses</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(summaryTable.reduce((acc: Number, curr: any) => acc + curr.flightcost, 0).toFixed(2))}</Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Margin</Typography>
                        <Typography sx={{
                            color: '#47A920',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(() => {
                            const revenueTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0);
                            const truckCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.truckcost, 0);
                            const interlineCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.interlinecost, 0);
                            const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                            const totalALExpenses = summaryTable.reduce((acc: Number, curr: any) => acc + curr.flightcost, 0);
                            const totalMargin = marginBeforeAL - totalALExpenses;
                            return totalMargin.toFixed(2);
                        })()}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Profit</Typography>
                        <Typography sx={{
                            color: '#47A920',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(() => {
                            const revenueTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0);
                            const truckCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.truckcost, 0);
                            const interlineCostTotal = summaryTable.reduce((acc: Number, curr: any) => acc + curr.interlinecost, 0);
                            const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                            const totalALExpenses = summaryTable.reduce((acc: Number, curr: any) => acc + curr.flightcost, 0);
                            const totalMargin = marginBeforeAL - totalALExpenses;
                            return totalMargin.toFixed(2);
                        })()}</Typography>
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{
                display: 'flex',
                gap: '45px',
                marginTop: '15px',
                marginLeft: '15px',
                marginBottom: '25px',
                flexWrap: 'wrap',
                '@media only screen and (max-width: 991px)': {
                    gap: '15px',
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '20px',
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                        border: '1px solid #75757552;',
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <img src={Airplain} alt="Revenue Icon" />
                        <Box>
                            <Typography sx={{
                                color: '#4C5F78',
                                fontSize: '24px',
                                fontWeight: '600',
                                '@media only screen and (max-width: 991px)': {
                                    fontSize: '20px',
                                },
                            }}>{flightData?.origin || '-'}</Typography>
                            <Typography sx={{
                                color: '#4C5F78',
                                fontSize: '16px',
                                fontWeight: '300',
                            }}></Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Org</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.origin || '-'}</Typography>
                    </Box>

                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',

                        }}>Dest</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.destination || '-'}</Typography>
                    </Box>

                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Aircraft</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.flight || '-'}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Carrier</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '20px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '18px',
                            },
                        }}>{flightData?.aircraft_type || '-'}</Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '25px',
                    flexWrap: 'wrap',
                    '@media only screen and (max-width: 575px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        border: '1px solid #75757552',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Revenue</Typography>
                        <Typography sx={{
                            color: '#37AE68',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0).toFixed(2)}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Interline</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(summaryTable.reduce((acc: any, curr: any) => acc + (curr.interlinecost || 0), 0).toFixed(2)) || '-'}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Truck/Supplier</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(summaryTable.reduce((acc: any, curr: any) => acc + (curr.truckcost || 0), 0).toFixed(2)) || '-'}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Cost</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(
                            (summaryTable.reduce((acc: any, curr: any) => acc + (curr.truckcost || 0), 0) +
                                summaryTable.reduce((acc: any, curr: any) => acc + (curr.interlinecost || 0), 0))
                        ).toFixed(2) || '-'}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Margin before AL</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>
                            {(() => {
                                const revenueTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0);
                                const truckCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.truckcost || 0), 0);
                                const interlineCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.interlinecost || 0), 0);
                                const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                                return marginBeforeAL.toFixed(2);
                            })()}
                        </Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total AL Expenses</Typography>
                        <Typography sx={{
                            color: '#4C5F78',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.flightcost || 0), 0).toFixed(2)}</Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Margin</Typography>
                        <Typography sx={{
                            color: '#47A920',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(() => {
                            const revenueTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0);
                            const truckCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.truckcost || 0), 0);
                            const interlineCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.interlinecost || 0), 0);
                            const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                            const totalALExpenses = summaryTable.reduce((acc: any, curr: any) => acc + (curr.flightcost || 0), 0);
                            const totalMargin = marginBeforeAL - totalALExpenses;
                            return totalMargin.toFixed(2);
                        })()}</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                    }}>
                        <Typography sx={{
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}>Total Profit</Typography>
                        <Typography sx={{
                            color: '#47A920',
                            fontSize: '24px',
                            fontWeight: '600',
                            '@media only screen and (max-width: 991px)': {
                                fontSize: '20px',
                            },
                        }}>{(() => {
                            const revenueTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0);
                            const truckCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.truckcost || 0), 0);
                            const interlineCostTotal = summaryTable.reduce((acc: any, curr: any) => acc + (curr.interlinecost || 0), 0);
                            const marginBeforeAL = revenueTotal - truckCostTotal - interlineCostTotal;
                            const totalALExpenses = summaryTable.reduce((acc: any, curr: any) => acc + (curr.flightcost || 0), 0);
                            const totalMargin = marginBeforeAL - totalALExpenses;
                            return totalMargin.toFixed(2);
                        })()}</Typography>
                    </Box>
                </Box>
            </Box>

            {/* <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '25px',
                marginBottom: '15px',
                flexWrap: 'wrap',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#F5F5F5',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    flexWrap: 'wrap',
                    '@media only screen and (max-width: 870px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',

                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Typography sx={{
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>Shipment Info</Typography>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },

                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable?.length}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>AWB</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.pieces, 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Pieces</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.grosswt, 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>GR WT</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.volume, 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Vol</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.density, 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Density</Typography>
                    </Box>


                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.chargewt, 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>CH WT</Typography>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#EBF7FF',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    '@media only screen and (max-width: 768px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Typography sx={{
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>Finance Info</Typography>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.costs, 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Cost</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px',
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Revenue</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{
                                ((summaryTable.reduce((acc: Number, curr: any) => acc + curr.revenue, 0)) - (summaryTable.reduce((acc: Number, curr: any) => acc + curr.costs, 0))
                                ).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Margin</Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#FFF6DD',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    '@media only screen and (max-width: 768px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Box sx={{
                        '@media only screen and (max-width: 575px)': {
                            textAlign: 'center',
                        },
                    }}>
                        <Typography sx={{
                            color: '#000',
                            fontSize: '16px',
                            paddingRight: '8px'
                        }}>Finance Info</Typography>
                        <Typography sx={{
                            color: '#000',
                            fontSize: '16px',
                            paddingRight: '8px'
                        }}>STB</Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>236,424</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Cost</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>169,844</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Revenue</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>66580</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Margin</Typography>
                    </Box>
                </Box>
            </Box> */}

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '25px',
                marginBottom: '15px',
                flexWrap: 'wrap',
            }}>
                {/* <Box sx={{
        display: 'flex',
        alignItems: 'center',
        background: '#0F8112',
        padding: '15px',
        gap: '15px',
        borderRadius: '10px',
        '@media only screen and (max-width: 870px)': {
            width: '100%',
            justifyContent: 'space-between',
        },
    }}>
        <Typography sx={{
            color: '#fff',
            fontSize: '16px',
            fontWeight: '300',
        }}>Sector</Typography>
        <Typography
            sx={{
                color: '#fff',
                fontSize: '16px',
                fontWeight: '600',
            }}
        >LHR-EVN</Typography>
    </Box> */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#F5F5F5',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    flexWrap: 'wrap',
                    '@media only screen and (max-width: 870px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Typography sx={{
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>Shipment Info</Typography>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },

                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable?.length || 0}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>AWB</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.pieces || 0), 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Pieces</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.grosswt || 0), 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>GR WT</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.volume || 0), 0)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Vol</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        borderRight: '1px solid ',
                        paddingRight: '8px',
                        '@media only screen and (max-width: 768px)': {
                            border: '0px',
                        },
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.density || 0), 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Density</Typography>
                    </Box>


                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.chargewt || 0), 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>CH WT</Typography>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#EBF7FF',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    '@media only screen and (max-width: 768px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Typography sx={{
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>Finance Info</Typography>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.costs || 0), 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Cost</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px',
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Revenue</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>{(
                            summaryTable.reduce((acc: any, curr: any) => acc + (curr.revenue || 0), 0) -
                            summaryTable.reduce((acc: any, curr: any) => acc + (curr.costs || 0), 0)
                        ).toFixed(2)}</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Margin</Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#FFF6DD',
                    padding: '15px',
                    gap: '16px',
                    borderRadius: '15px',
                    '@media only screen and (max-width: 768px)': {
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                    '@media only screen and (max-width: 575px)': {
                        flexWrap: 'wrap',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '10px',
                        padding: '10px',
                        borderRadius: '10px',
                    },
                }}>
                    <Box sx={{
                        '@media only screen and (max-width: 575px)': {
                            textAlign: 'center',
                        },
                    }}>
                        <Typography sx={{
                            color: '#000',
                            fontSize: '16px',
                            paddingRight: '8px'
                        }}>Finance Info</Typography>
                        <Typography sx={{
                            color: '#000',
                            fontSize: '16px',
                            paddingRight: '8px'
                        }}>STB</Typography>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>236,424</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Cost</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>169,844</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Revenue</Typography>
                    </Box>

                    <Box sx={{
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '16px',
                        paddingRight: '8px'
                    }}>
                        <Typography sx={{
                            color: '#333333',
                            fontWeight: '700',
                            fontSize: '18px',
                        }}>66580</Typography>
                        <Typography sx={{
                            fontSize: '12px',
                        }}>Total Margin</Typography>
                    </Box>
                </Box>
            </Box>


            <TableContainer component={Paper}>
    <Table sx={{
        '& thead': {
            background: '#4C5F78',
            color: '#fff',
            '& tr': {
                '& th': {
                    padding: '8px 17px',
                    fontSize: '16px',
                    fontWeight: "700",
                    color: '#fff',
                    textAlign: 'center',
                    borderLeft: '1px solid #fff',
                },
            },
        },
    }}>
        <TableHead>
            <TableRow>
                <TableCell>AWB</TableCell>
                <TableCell>Org</TableCell>
                <TableCell>Dest</TableCell>
                <TableCell>PCs</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Vol</TableCell>
                <TableCell>Density</TableCell>
                <TableCell>Flight Cost</TableCell>
                <TableCell>Truck Cost</TableCell>
                <TableCell>Interline Cost</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Truck</TableCell>
                <TableCell>Oth Flight</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Revenue</TableCell>
                <TableCell>Yield</TableCell>
                <TableCell>AWB Status</TableCell>
                <TableCell>Space status</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Replan</TableCell>
            </TableRow>
        </TableHead>
        {isConsignmentFound ? (
            <TableBody className='' sx={{
                background: '#F7F7F7',
            }}>
                {summaryTable.map((summary: any, index: number) =>
                    <TableRow key={index} sx={{
                        background: '#fff',
                        border: '2px solid #F7F7F7',
                        '& td': {
                            padding: '2px 8px',
                            color: '#333333',
                            fontSize: "14px",
                            fontWeight: '700',
                            textAlign: 'center',
                        },
                    }}>
                        <TableCell>{summary.awbno || '-'}</TableCell>
                        <TableCell>{summary.route ? summary.route.split('-')[0] : '-'}</TableCell>
                        <TableCell>{summary.route ? summary.route.split('-')[1] : '-'}</TableCell>
                        <TableCell>{summary.pieces || '-'}</TableCell>
                        <TableCell>{summary.chargewt?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.volume?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.density?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.flightcost?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.truckcost?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.interlinecost?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.agent || '-'}</TableCell>
                        <TableCell>{summary.truck_id || '-'}</TableCell>
                        <TableCell>{summary.oth_flight || '-'}</TableCell>
                        <TableCell>{summary.product || '-'}</TableCell>
                        <TableCell>{summary.remarks || '-'}</TableCell>
                        <TableCell>{summary.currency || '-'}</TableCell>
                        <TableCell>{summary.costs?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.revenue?.toFixed(2) || '-'}</TableCell>
                        <TableCell>{summary.yield?.toFixed(2) || '-'}</TableCell>
                        <TableCell>
                            <Box sx={{
                                background: '#0F8112',
                                width: '140px',
                                height: '27px',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '8px',
                            }}>{summary.awb_status || '-'}</Box>
                        </TableCell>
                        <TableCell>{summary.space_status || '-'}</TableCell>
                        <TableCell>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id={`demo-select-small-label-${index}`}>Select</InputLabel>
                                <Select
                                    labelId={`demo-select-small-label-${index}`}
                                    id={`demo-select-small-${index}`}
                                    value={consignmentPlans[index]}
                                    label="Select"
                                    onChange={(event: { target: { value: any; }; }) => handleDropdownChange(index, event.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'KK'}>KK</MenuItem>
                                    <MenuItem value={'LL'}>LL</MenuItem>
                                    <MenuItem value={'UU'}>UU</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <Box sx={{
                                background: '#4C5F78',
                                width: '70px',
                                height: '30px',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '8px',
                                cursor: 'pointer',
                            }}>
                                Replan
                            </Box>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        ) : (
            <Typography sx={{
                color: '#333333',
                fontWeight: '700',
                fontSize: '18px',
            }}>No Records Found</Typography>
        )}
    </Table>
</TableContainer>



        </Box >
    );
};

export default FlightSearch;
