import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './App.css';
import Header from './Components/Header';
import SpaceControl from './Pages/SpaceControl';
import FlightSearch from './Pages/FlightSearch';
import { Footer } from './Components/Footer';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Routes';


const theme = createTheme();

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;