import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, PaginationItem, Button } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ReactComponent as PreviousIcon } from '../../Assets/Images/previous.svg';
import { ReactComponent as NextIcon } from '../../Assets/Images/next.svg';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useState, useEffect } from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Loader from '../../Components/Loader'
import { Label } from '@mui/icons-material';
//import FlightSearch from '../FlightSearch

const SpaceControl = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [responseData, setResponseData] = useState<any>([])

    const [pageCount, setPageCount] = useState<any>(0)

    const [pageApi, setPageApi] = useState(1);
    const [isFlight, setIsFlight] = useState<any>(null)
    const [flightName, setFlightName] = useState<any>(null)
    const [autoComplete, setAutoComplete] = useState<any>(null);
    const [airline_name, setAirline_name] = useState<any>(null);
    const [flight_date_from, setFlightDateFrom] = useState<any>(null);
    const [flight_date_to, setFlightDateTo] = useState<any>(null);
    const [isSearching, setIsSearching] = useState<Boolean>(false);

    //API
    const ApiAddress = axios.create({
        baseURL: "",
    });
    useEffect(() => {
        setIsLoading(true);
        if(isSearching){
            filterData()
        } else {
            axios.get(`https://test.uatsharpssop.co.uk/flight_info?page=${pageApi}&page_size=10`)
                .then((response: any) => { 
                    setResponseData(response.data.flights); 
                    setPageCount(response.data.total_pages); 
                })
                .catch((err: any) => console.log(err)).finally(() => { setIsLoading(false); })
        }
    }, [pageApi]);

    //Format Date
    const formatDate = (date: string) => {
        const formated_date = moment(date).format('MM-DD-YYYY');
        return formated_date;
    }
    // Format date and timeas well 
    const formatTime = (date: string) => {
        const formated_time = moment(date).format('MM-DD-YYYY(HH:mm)')
        return formated_time;
    }
    console.log('autoComplete', autoComplete)
    // api for autoComplte functionality
    useEffect(() => {
        axios.get('https://test.uatsharpssop.co.uk/airline_list')
            .then((response: any) => {
                const data = response?.data?.airlines?.map((item: any) => ({ label: item.airline, value: item.airline }));
                setAutoComplete(data)
            })
    }, [])

    // api for flight data 
    useEffect(() => {
        axios.get('https://test.uatsharpssop.co.uk/flight_list')
            .then((response: any) => {
                const data = response?.data?.flights
                let temp_data: any[] = [];
                for (let i = 0; i < data.length; i++) {
                    temp_data.push(data[i].flight);
                }
                console.log("data", temp_data[0])
                setFlightName(temp_data)
            })
    }, [])

    
    const filterData = async () => {
        if(airline_name && airline_name.length > 0 || isFlight && isFlight.length > 0 || flight_date_from || flight_date_to){
            setIsSearching(true)
        } else {
            setIsSearching(false)
        }
        setPageApi(1);
        const dateObject = new Date(flight_date_from);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Adding 1 to month and padding with '0' if necessary
        const day = String(dateObject.getDate()).padStart(2, '0'); // Padding with '0' if necessary
    
        const fromDate = `${year}-${month}-${day}`;
    
        const dateObjectTo = new Date(flight_date_to);
        const yearTo = dateObjectTo.getFullYear();
        const monthTo = String(dateObjectTo.getMonth() + 1).padStart(2, '0'); // Adding 1 to month and padding with '0' if necessary
        const dayTo = String(dateObjectTo.getDate()).padStart(2, '0'); // Padding with '0' if necessary
    
        const toDate = `${yearTo}-${monthTo}-${dayTo}`;
        let url = `https://test.uatsharpssop.co.uk/flight_info?page=${pageApi}&page_size=10`;
        if (airline_name) url += `&airline_name=${airline_name}`;
        if (isFlight) url += `&flight=${isFlight}`;
        if (flight_date_from) url += `&flight_date_from=${fromDate}`;
        if (flight_date_to) url += `&flight_date_to=${toDate}`;
        const res = await axios.get(url);
        if (res) {
            setResponseData(res?.data.flights)
            setPageCount(res?.data.total_pages)
        }
    }

    const navigate = useNavigate();
    return (

        <Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '5px solid #f7f7f7',
                paddingBottom: '10px',
                '@media only screen and (max-width: 575px)': {
                    flexDirection: 'column',
                    gap: '10px',
                },
            }}>
                <Typography sx={{
                    color: "#5B5B5B",
                    fontSize: '22px',
                    fontWeight: '700',
                    height: '40px',
                    lineHeight: '40px',
                    background: '#EEEFF1',
                    padding: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '8px solid #4C5F78',
                    '@media only screen and (max-width: 575px)': {
                        fontSize: '18px',
                    },

                }} variant="h4">Space Control Dashboard
                </Typography>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event: any, value: any) => setAirline_name(value.label)}
                    // value={}
                    // getOptionLabel={(option: any) => console.log(option.label)}
                    options={autoComplete}
                    sx={{ mt: 1.1, width: 170 }}
                    renderInput={(params) => <TextField {...params} label="Airlines" />}
                />

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event: any, value: any) => {
                        console.log('value', value)
                        return setIsFlight(value)
                    }}
                    options={flightName}
                    sx={{ mt: 1.1, width: 170 }}
                    renderInput={(params) => <TextField {...params} label="Flight No" />}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="From"
                            value={flight_date_from}
                            onChange={(e) => setFlightDateFrom(e)} />
                    </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label="To"
                            value={flight_date_to}
                            onChange={(e) => setFlightDateTo(e)} />
                    </DemoContainer>
                </LocalizationProvider>


                <Button sx={{
                    background: '#4C5F78 !important',
                    color: '#fff',
                }} onClick={() => filterData()}>Search</Button>
            </Box>


            <TableContainer component={Paper}>
                <Table sx={{
                    '& thead': {
                        background: '#4C5F78',
                        color: '#fff',
                        '& tr': {
                            '& th': {
                                padding: '8px 0 0 0     ',
                                fontSize: '16px',
                                fontWeight: "700",
                                color: '#fff',
                                textAlign: 'center',
                                borderLeft: '1px solid #fff',
                            },
                        },
                    },
                }}>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell sx={{
                                padding: '8px 17px !important',
                            }}>Flight Id</TableCell> */}
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                            }}>Airlines</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Flight No</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Flight Date</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Board Point</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Route</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>ETD</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>ETA</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Flight Status</TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content'
                            }}>Aircraft</TableCell>
                            <TableCell>Summary
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    borderTop: '1px solid #fff',
                                    '& th': {
                                        border: '0',
                                        padding: '0 !important',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <TableCell sx={{
                                        borderLeft: "0px !important",
                                    }}>KK</TableCell>
                                    <TableCell>LL</TableCell>
                                    <TableCell>UU</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content' }}>Cargo Capacity
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    borderTop: '1px solid #fff',
                                    '& th': {
                                        border: '0',
                                        padding: '0 !important',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <TableCell sx={{
                                        borderLeft: "0px !important",
                                    }}>Gross</TableCell>
                                    <TableCell>Vol</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content' }}>Used Capacity
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    borderTop: '1px solid #fff',
                                    '& th': {
                                        border: '0',
                                        padding: '0 !important',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <TableCell sx={{
                                        borderLeft: "0px !important",
                                    }}>Gross</TableCell>
                                    <TableCell>Vol</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content' }}>Confirm Capacity
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    borderTop: '1px solid #fff',
                                    '& th': {
                                        border: '0',
                                        padding: '0 !important',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <TableCell sx={{
                                        borderLeft: "0px !important",
                                    }}>Gross</TableCell>
                                    <TableCell>Vol</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                whiteSpace: 'nowrap',
                                width: 'fit-content' }}>Available Capacity
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    borderTop: '1px solid #fff',
                                    '& th': {
                                        border: '0',
                                        padding: '0 !important',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }}>
                                    <TableCell sx={{
                                        borderLeft: "0px !important",
                                    }} >Gross</TableCell>
                                    <TableCell>Vol</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{
                                padding: '8px 17px !important',
                                width: 'fit-content',
                                    whiteSpace: 'nowrap',
                            }}>Flight Summary</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody className='' sx={{
                        background: '#F7F7F7'
                    }}>


                        {responseData.map((data: any) => <TableRow className='test' sx={{
                            background: '#fff',
                            border: '2px solid #F7F7F7',
                            '& td': {
                                padding: '10px',
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            {/* <TableCell>{data.flightdetail_id}</TableCell> */}
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{data.airline_id}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{data.flight}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{formatDate(data.flightdate)}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{data.origin}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{data.origin}-{data.destination}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{formatTime(data.stddeparturetime)}</TableCell>
                            <TableCell style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{formatTime(data.stdarrivaltime)}</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell  style={{ width: 'fit-content',whiteSpace: 'nowrap' }}>{data.aircraft_type}</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>{data.flight_info.reservation_status.kk?.toFixed(2) ?? "-"}</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>{data.flight_info.reservation_status.ll ?? "-"}</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>{data.flight_info.reservation_status.uu ?? "-"}</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>{data.wtcapacity}</TableCell>
                                    <TableCell>{data.volcapacity}</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>{data.flight_info.used_capacity.used_weight?.toFixed(0) ?? "-"}</TableCell>
                                    <TableCell>{data.flight_info.used_capacity.used_volume?.toFixed(0) ?? "-"}</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>{data.flight_info.confirmed_capacity.confirmed_weight?.toFixed(0) ?? "-"}</TableCell>
                                    <TableCell>{data.flight_info.confirmed_capacity.confirmed_volume?.toFixed(0) ?? "-"}</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>{data.flight_info.available_capacity.weight?.toFixed(0) ?? "-"}</TableCell>
                                    <TableCell>{data.flight_info.available_capacity.volume?.toFixed(0) ?? "-"}</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer',display: 'flex',alignItems: 'center', justifyContent: 'center',borderBottom:'none' }} onClick={() => navigate(`/flight-search/${data.flightdetail_id}`)}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow>)}


                        {/* <TableRow sx={{
                            background: '#fff',
                            border: '10px solid #F7F7F7',
                            '& td': {
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            <TableCell>Airline A</TableCell>
                            <TableCell>AB123</TableCell>
                            <TableCell>01-Jan-23</TableCell>
                            <TableCell>XYZ</TableCell>
                            <TableCell>XYZ-ABC</TableCell>
                            <TableCell>10:00</TableCell>
                            <TableCell>12:00</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell>A320</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',

                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>10</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>5</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>1</TableCell>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>22,000,00</TableCell>
                                    <TableCell>140</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>18,000,00</TableCell>
                                    <TableCell>100</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>8,000.00</TableCell>
                                    <TableCell>60</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>2000.00</TableCell>
                                    <TableCell>40</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => navigate('/flight-search')}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{
                            background: '#fff',
                            border: '10px solid #F7F7F7',
                            '& td': {
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            <TableCell>Airline A</TableCell>
                            <TableCell>AB123</TableCell>
                            <TableCell>01-Jan-23</TableCell>
                            <TableCell>XYZ</TableCell>
                            <TableCell>XYZ-ABC</TableCell>
                            <TableCell>10:00</TableCell>
                            <TableCell>12:00</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell>A320</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',

                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>10</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>5</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>1</TableCell>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>22,000,00</TableCell>
                                    <TableCell>140</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>18,000,00</TableCell>
                                    <TableCell>100</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>8,000.00</TableCell>
                                    <TableCell>60</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>2000.00</TableCell>
                                    <TableCell>40</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => navigate('/flight-search')}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{
                            background: '#fff',
                            border: '10px solid #F7F7F7',
                            '& td': {
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            <TableCell>Airline A</TableCell>
                            <TableCell>AB123</TableCell>
                            <TableCell>01-Jan-23</TableCell>
                            <TableCell>XYZ</TableCell>
                            <TableCell>XYZ-ABC</TableCell>
                            <TableCell>10:00</TableCell>
                            <TableCell>12:00</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell>A320</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',

                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>10</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>5</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>1</TableCell>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>22,000,00</TableCell>
                                    <TableCell>140</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>18,000,00</TableCell>
                                    <TableCell>100</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>8,000.00</TableCell>
                                    <TableCell>60</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>2000.00</TableCell>
                                    <TableCell>40</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => navigate('/flight-search')}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{
                            background: '#fff',
                            border: '10px solid #F7F7F7',
                            '& td': {
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            <TableCell>Airline A</TableCell>
                            <TableCell>AB123</TableCell>
                            <TableCell>01-Jan-23</TableCell>
                            <TableCell>XYZ</TableCell>
                            <TableCell>XYZ-ABC</TableCell>
                            <TableCell>10:00</TableCell>
                            <TableCell>12:00</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell>A320</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',

                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>10</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>5</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>1</TableCell>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>22,000,00</TableCell>
                                    <TableCell>140</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>18,000,00</TableCell>
                                    <TableCell>100</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>8,000.00</TableCell>
                                    <TableCell>60</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>2000.00</TableCell>
                                    <TableCell>40</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => navigate('/flight-search')}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{
                            background: '#fff',
                            border: '10px solid #F7F7F7',
                            '& td': {
                                color: '#333333',
                                fontSize: "14px",
                                fontWeight: '700',
                            },
                        }}>
                            <TableCell>Airline A</TableCell>
                            <TableCell>AB123</TableCell>
                            <TableCell>01-Jan-23</TableCell>
                            <TableCell>XYZ</TableCell>
                            <TableCell>XYZ-ABC</TableCell>
                            <TableCell>10:00</TableCell>
                            <TableCell>12:00</TableCell>
                            <TableCell>
                                <Chip label="Open" color="primary" />
                            </TableCell>
                            <TableCell>A320</TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    gap: '24px',

                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell sx={{
                                        background: '#0F8112',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>10</TableCell>
                                    <TableCell sx={{
                                        background: '#E21414',
                                        width: '50px',
                                        height: '27px',
                                        color: '#fff !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>5</TableCell>
                                    <TableCell sx={{
                                        background: '#FEC937',
                                        width: '50px',
                                        height: '27px',
                                        color: '#000 !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '8px',
                                    }}>1</TableCell>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>22,000,00</TableCell>
                                    <TableCell>140</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>


                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>18,000,00</TableCell>
                                    <TableCell>100</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>8,000.00</TableCell>
                                    <TableCell>60</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    '& td': {
                                        borderBottom: '0',
                                    },
                                }}>
                                    <TableCell>2000.00</TableCell>
                                    <TableCell>40</TableCell>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => navigate('/flight-search')}>
                                <FlightIcon />
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
                <Box sx={{
                    marginY: '20px',
                    marginLeft: '10px',
                }}>
                    <Stack spacing={2}>
                        <Pagination
                            sx={{
                                '& ul': {
                                    width: '300px',
                                    border: '1px solid #D7DAE2',
                                    borderRadius: '6px',
                                    '& li': {
                                        '& button': {
                                            margin: '0',
                                            padding: '0',
                                            border: '0',
                                            '&.Mui-selected': {
                                                background: '#4E4E4E',
                                                color: '#fff',
                                            },
                                        },
                                    },
                                },
                            }}
                            count={pageCount}
                            page={pageApi}
                            variant="outlined"
                            shape="rounded"
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{ previous: PreviousIcon, next: NextIcon }}
                                    {...item}
                                />
                            )}
                            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                console.log('page value', value)
                                setPageApi(value)
                            }}
                        />

                    </Stack>
                </Box>
            </TableContainer>
        </Box>
    );
};

export default SpaceControl;
