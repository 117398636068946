import { Box, Typography } from '@mui/material'
import React from 'react'

export const Footer = () => {
    return (

        <>


            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                background: '#f7f7f7',
                padding: '12px',
                '@media only screen and (max-width: 1024px)': {
                    flexDirection: 'column',
                    gap: '15px',
                },

            }}>
                <Box>
                    <Typography sx={{
                        color: '#000',
                        fontWeight: '500',
                        '@media only screen and (max-width: 1199px)': {
                            fontSize: '12px',
                        },
                    }}>Copyright 2024 e-CARGOWARE LTD, All Rights Reserved, powered by e-CARGOWARE</Typography>
                </Box>
            </Box>
        </>
    )
}
