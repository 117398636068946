import { Route, Routes } from "react-router-dom";
import SpaceControl from "../Pages/SpaceControl";
import FlightSearch from "../Pages/FlightSearch";
import { Layout } from "../Pages/Layout";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={`/`} element={<Layout />} >
                <Route index element={<SpaceControl />} />
                <Route path={`/flight-search/:flightid`} element={<FlightSearch />} />
            </Route>
            <Route path={`*`} element={<>Page Not Found!</>} />
        </Routes>
    );
};

export default AppRoutes;
