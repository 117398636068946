import React from 'react';
import { CssBaseline, Box } from '@mui/material';

import DrawerComponent from './DrawerComponent';
import AppBarComponent from './AppBarComponent';
import { DrawerHeader } from './styled';

interface IProps {
    children: React.ReactNode;
}

const Header = ({ children }: IProps) => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBarComponent
                open={open}
                handleDrawerOpen={handleDrawerOpen}
            />

            {/* <DrawerComponent
                open={open}
                handleDrawerClose={handleDrawerClose}
            /> */}

            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'hidden', }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}

export default Header;