import React, { useState } from 'react';
import { Box, FormControl, IconButton, MenuItem, Select, Toolbar, Typography, SelectChangeEvent, Input, Drawer, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar } from './styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    open: boolean;
    handleDrawerOpen: () => void;
}

const AppBarComponent = ({ open, handleDrawerOpen }: IProps) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedValue(event.target.value);
    };
    const [openDrawer, setOpenDrawer] = useState(false)

    return (
        <AppBar position="fixed" open={open} >
            <Toolbar className='' sx={{
                background: '#fff',
                justifyContent: 'space-between',
            }}>
                {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                        color: '#4C5F78',
                    }}
                >
                    <MenuIcon />
                </IconButton> */}

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: '230px',
                    '@media only screen and (max-width: 1440px)': {
                        gap: '20px',
                    },
                    '@media only screen and (max-width: 1200px)': {
                        display: 'none',
                    },

                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    '& .MuiTypography-root': {
                                        color: '#000',
                                        '&:p': {
                                            fontStyle: 'italic',
                                        },
                                    },
                                }}>
                                    <Typography>{new Date().toUTCString()}</Typography>

                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "2px", }}>
                            <Box>
                                <IconButton aria-label="account">
                                    <AccountCircleIcon />
                                </IconButton>
                            </Box>
                            <Typography sx={{ color: '#000' }}>|</Typography>
                            <Typography sx={{ color: '#000', paddingLeft: '5px', }}>Welcome</Typography>
                            
                        </Box>
                    </Box>
                </Box>
                <Box className=""
                    sx={{
                        display: 'none',
                        '@media only screen and (max-width: 1200px)': {
                            display: 'block',
                        },
                    }}
                >
                    <Box onClick={() => setOpenDrawer(true)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: "2px", }}>
                            <Box>
                                <IconButton aria-label="account">
                                    <AccountCircleIcon />
                                </IconButton>
                            </Box>
                            <Typography sx={{ color: '#000' }}>|</Typography>
                            <Typography sx={{ color: '#000', paddingLeft: '5px', }}>Welcome</Typography>
                            <Box>
                                <Select
                                    defaultValue="AIRTRANS"
                                    sx={{
                                        width: 'auto',
                                        fontSize: '16px',
                                        '> div': {
                                            margin: '0',
                                            border: 'none',
                                            padding: '5px',
                                        },
                                        'fieldset': {
                                            border: 'none',
                                        },
                                    }}
                                >
                                    <option value="option1">AIRTRANS</option>
                                    <option value="option2">AIRTRANS</option>
                                    <option value="option3">AIRTRANS</option>
                                    <option value="option4">Option 4</option>
                                </Select>
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <Drawer
                    anchor='right'
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                >
                    <Box sx={{
                        width: '300px',
                        padding: '10px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            marginTop: '80px',
                        }}>

                            <Box onClick={() => setOpenDrawer(false)} sx={{
                                position: 'absolute',
                                top: '63px',
                                right: '0',
                            }}>
                                <CloseIcon />
                            </Box>
                            <Typography sx={{ color: '#000' }}>Station:</Typography>
                            <Typography sx={{
                                color: '#4C5F78',
                                fontWeight: '600',
                            }}>Larnaca Airport</Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '5px',
                            flexDirection: 'column',
                            marginTop: '10px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <Typography sx={{ color: '#000' }}>Service:</Typography>
                                <Typography sx={{
                                    color: '#4C5F78',
                                    fontWeight: '600',
                                }}>Handling</Typography>
                            </Box>
                            <FormControl sx={{
                                width: '100%',
                            }}>
                                <Select
                                    value={selectedValue}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        border: '1px solid #4C5F78',
                                        borderRadius: '0px',
                                        '& .Mui-focused': {
                                            backgroundColor: 'transparent',
                                        },
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '& div': {
                                            padding: '5px',
                                            margin: 0,
                                        },
                                        width: 'auto',
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an option
                                    </MenuItem>
                                    <MenuItem value="1">Switch 1</MenuItem>
                                    <MenuItem value="2">Switch 2</MenuItem>
                                    <MenuItem value="3">Switch 3</MenuItem>
                                </Select>
                            </FormControl>
                            <Input
                                type="text"
                                placeholder="Menu Search"
                                aria-label="search input"
                                sx={{
                                    border: '1px solid #4C5F78',
                                    borderRadius: '0px',
                                    padding: '3px',
                                    fontSize: '14px',
                                    width: '100%',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                        outline: 'none',
                                    },
                                    '&::after': {
                                        display: 'none',
                                    },
                                    '&::before': {
                                        display: 'none',
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;
