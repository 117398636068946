import  React from 'react';
import { Footer } from "../../Components/Footer"
import Header from "../../Components/Header"
import { Outlet } from 'react-router';

export const Layout = () => {
  return (
    <Header>
      <Outlet />
      <Footer />
    </Header>
  )
}
